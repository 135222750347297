/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "bootstrap/dist/css/bootstrap.css"
import "./src/styles/scss/style.scss"

// Metodo para detectar el cambio de ruta
// Ayuda a saber cuando este en la pagina de ecommerce y cargar los elementos
// del DOM para controlar el swticher de planes
// onRouteUpdate es un metodo reservado de la API de gatsby
// ver todos los metodos de la API https://www.gatsbyjs.org/docs/browser-apis/#onRouteUpdate
export const onRouteUpdate = ({ location, prevLocation }) => {
  const currentPath = location.pathname
  if (
    currentPath === "/tienda-en-linea/" ||
    currentPath === "/tienda-en-linea"
  ) {
    var e = document.getElementById("filt-monthly"),
      d = document.getElementById("filt-hourly"),
      t = document.getElementById("switcher"),
      m = document.getElementById("monthly"),
      y = document.getElementById("yearly"),
      btnStarterPlanMes = document.getElementById("btn-starter-mes"),
      btnAdvacedPlanmes = document.getElementById("btn-advanced-mes"),
      btnStarterPlanAnio = document.getElementById("btn-starter-anio"),
      btnStarterPlanAnio = document.getElementById("btn-advanced-anio")

    e.addEventListener("click", function () {
      t.checked = false
      e.classList.add("toggler--is-active")
      d.classList.remove("toggler--is-active")
      m.classList.remove("hide")
      y.classList.add("hide")
    })

    d.addEventListener("click", function () {
      t.checked = true
      d.classList.add("toggler--is-active")
      e.classList.remove("toggler--is-active")
      m.classList.add("hide")
      y.classList.remove("hide")
    })

    t.addEventListener("click", function () {
      d.classList.toggle("toggler--is-active")
      e.classList.toggle("toggler--is-active")
      m.classList.toggle("hide")
      y.classList.toggle("hide")
    })
  }
}
