// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-database-js": () => import("./../src/pages/database.js" /* webpackChunkName: "component---src-pages-database-js" */),
  "component---src-pages-digital-marketing-js": () => import("./../src/pages/digital-marketing.js" /* webpackChunkName: "component---src-pages-digital-marketing-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-software-factory-js": () => import("./../src/pages/software-factory.js" /* webpackChunkName: "component---src-pages-software-factory-js" */),
  "component---src-pages-terms-conditions-js": () => import("./../src/pages/terms-conditions.js" /* webpackChunkName: "component---src-pages-terms-conditions-js" */),
  "component---src-pages-tienda-en-linea-js": () => import("./../src/pages/tienda-en-linea.js" /* webpackChunkName: "component---src-pages-tienda-en-linea-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

